export default class Constants {
    // Local AppDeft URL
    // static HOST = "https://fundamentalmusics.appdeft.biz/";
    // static IMG_URL = "https://fundamentalmusic.appdeft.in/";
    // static IMAGE_BASE_URL = "https://fundamentalmusic.appdeft.in/storage";
    // static BASE_URL = "https://fundamentalmusic.appdeft.in/api/admin/";

    // Production URL
    static HOST = "https://fundamentalmusic.org/";
    static IMG_URL = "https://app.fundamentalmusic.org/";
    static IMAGE_BASE_URL = "https://app.fundamentalmusic.org/storage";
    static BASE_URL = "https://app.fundamentalmusic.org/api/admin/";

    static END_POINT = {
        LOGIN: "login",
        SENT_OTP: "email-otp",
        VERIFY_OTP: "verify-otp",
        RESET_PASSWORD: "reset-password",
        GET_INSTRUMENTS: "instruments",
        GET_INSTRUMENT: "view-instrument/",
        ADD_INSTRUMENTS: "add-instrument",
        EDIT_INSTRUMENT: "edit-instrument/",
        DELETE_INSTRUMENT: "delete-instrument/",
        GET_PURCHASE_PLANS: "instrument-purchase-plans",
        DELETE_PURCHASE_PLAN: "delete-instrument-purchase-plan/",
        ADD_PURCHASE_PLAN: "add-instrument-purchase-plan",
        GET_PURCHASE_PLAN: "view-instrument-purchase-plan/",
        EDIT_PURCHASE_PLAN: "edit-instrument-purchase-plan/",
        BUILD_FORM: "build-form",
        EDIT_BUILD_FORM: "edit-form/",
        GET_FORMS: "forms",
        EDIT_FORM: "edit-form/",
        DELETE_FORM: "delete-form/",
        VIEW_FORM: "view-form/",
        SCHOOL_LESSONS: "get-lessons",
        TEACHER_ONLINE_STUDENTS: "online-lessons-students",
        ADD_SCHOOL_LESSONS: "add-school-lesson",
        DELETE_SCHOOL_LESSONS: "delete-school-lesson/",
        DELETE_ONLINE_LESSONS: "online-lessons/delete/",
        GET_SCHOOL_LESSON: "view-school-lesson/",
        ADD_ONLINE_LESSON: "online-lessons/store",
        EDIT_SCHOOL_LESSON: "edit-school-lesson/",
        VIEW_ONLINE_LESSON: "online-lessons/view/",
        EDIT_ONLINE_LESSON: "online-lessons/edit/",
        ONLINE_LESSONS_GRADE: "online-lesson-grades/",
        GET_SCHOOLS: "schools",
        GET_SCHOOLS_DROPDOWN: "school-dropdown",
        ADD_SCHOOLS: "add-school",
        DELETE_SELECTED_INSTRUMENT: "delete-selected-instruments",
        GET_BOOKS: "books",
        DELETE_BOOKS: "delete-book/",
        VIEW_BOOK: "view-book/",
        ADD_BOOKS: "add-book",
        EDIT_BOOK: "edit-book/",
        DELETE_SELECTED_BOOKS: "delete-selected-books",
        DELETE_SCHOOL: "delete-school/",
        DELETE_SELECTED_SCHOOL: "delete-selected-school",
        EDIT_SCHOOL: "edit-school/",
        VIEW_SCHOOL: "view-school/",
        DELETE_SELECTED_PURCHASE_PLAN: "delete-selected-instrument-purchase-plans",
        GET_PROFILE: "profile",
        FETCH_STUDENTS: "students",
        VIEW_STUDENT: "student-detail/",
        FETCH_PRIVELEGES: "privileges",
        ADD_TEACHER: "add-teacher",
        GET_TEACHERS: "teachers",
        GET_TEACHERS_DROPDOWN: "teacher-dropdown",
        GET_LESSON_TYPE_FILTER: "get-lessons/",
        GET_LESSONS_SCHOOL_TYPE: "class-type-lessons/",
        VIEW_TEACHER: "view-teacher/",
        EDIT_TEACHER: "edit-teacher/",
        GET_INVOICES: "family-and-invoices",
        GET_INVOICE_TRANSACTIONS: "trasactions/",
        ADD_TRANSACTION: "add-trasaction",
        EDIT_TRANSACTION: "edit-transaction",
        DELETE_TEACHER_ATTACHMENT: "delete-attachment/",
        DELETE_SCHOOL_ATTACHMENT: "delete-school-attachment/",
        DELETE_TEACHER: "delete-teacher/",
        DELETE_SELECTED_TEACHER: "delete-selected-teacher",
        CHANGE_TEACHER_PASSWORD: "change-password/",
        TEACHER_ENABLE_DISABLE: "change-teacher-access/",
        DELETE_SELECTED_LESSONS: "lessons/delete",
        GET_INVOICE: "invoices/",
        ADD_INVOICE: "add-invoice",
        VIEW_INVOICE: "view-invoice",
        DELETE_INVOICE: "delete-invoice/",
        VOID_INVOICE: "void-invoice/",
        ADD_AUTO_INVOICE: "auto-invoicing",
        EMAIL_DROPDOWN: "all-emails-dropdown",
        GET_MESSAGE_HISTORY: "email-history",
        GET_DASHBOARD_LIST: "dashboard",
        GET_OVERDUE_ATTENDANCE: "overdue-attendance",
        GET_PAST_NOTES: "past-notes/",
        SET_ALL_UNRECORDED: "set-all-attendance",
        VIEW_TRANSACTION: "view-transaction/",
        DELETE_TRANSACTION: "delete-transaction",
        VIEW_AUTO_INVOICING: "get-auto-invoicing/",
        PAYMENT_INVOICE: "make-invoice-payment/",

        //Categories

        ADD_CATEGORY: "add-category",
        GET_CATEGORY: "categories",
        DELETE_CATEGORY: "delete-category/",
        VIEW_CATEGORY: "view-category/",
        EDIT_CATEGORY: "edit-category/",

        //Events

        ADD_EVENTS: "add-event",
        GET_EVENTS: "events",
        VIEW_EVENT: "view-event/",
        EDIT_EVENT: "edit-event/",
        DELETE_EVENT: "delete-repeat-events/",
        DELETE_ALL_EVENTS: "delete-all-events",
        ADD_ATTENDENCE: "add-attendance",
        VIEW_ATTENDENCE: "student-attendance",
        CLONE_EVENTS: "clone-events",

        //Email

        SEND_NEW_EMAIL: "send-email",
        GET_EMAIL: "invoice-email/",
        VIEW_EMAIL: "view-email/",
        SEND_INVOICE: "send-invoice-email/",

        //Teacher

        ADD_EDIT_PREFRENCES: "add-edit-preference",
        VIEW_PREFRENCES: "view-preference/",
        TEACHER_EVENTS: "teacher-events/",
        TEACHER_ATTANDENCE: "attendance-and-notes/",
        TEACHER_STUDENTS: "teacher-students/",
        TEACHER_CATEGORIES: "teacher-categories/",
        TEACHER_ASSIGN_VIEW: "view-assign-student",
        ASSIGN_STUDENT: "assign-student",
        GET_AVAILABILITIES: "availabilities/",
        ADD_TEACHER_AVAILABILITY: "add-availability",
        VIEW_TEACHER_AVAILABILITY: "view-availability/",
        VIEW_TEACHER_SCHOOLS: "teacher-schools/",
        EDIT_TEACHER_AVAILABILITY: "edit-availability/",
        DELETE_TEACHER_AVAILABILITY: "delete-availability/",
        GET_TEACHER_EVENTS: "teacher-events",
        DELETE_ASSIGN_STUDENT: "delete-assign-student/",
        VIEW_TEACHER_TOKEN: "view-as-teacher-token/",

        //School

        SCHOOL_GRADES: "school-grades/",
        SCHOOL_INSTRUMENT: "instrument-view/",
        GRADE_CLASS_TYPE: "grade-class-types",
        CLASS_TYPE_PROGRAMS: "class-type-programs",
        SCHOOL_PROGRAM_INSTRUMENTS: "program-intruments/",

        //Family

        GET_FAMILY: "parents",
        GET_FAMILY_DROPDOWN: "parents-dropdown",
        DISABLE_AUTO_INVOICE: "disable-auto-invoicing",

        ADD_CARD: "add-parent-card/",

        //  Student

        ADD_STUDENT: "add-student",
        EDIT_STUDENT: "edit-student/",
        DELETE_STUDENT_ATTACHMENT: "delete-student-attachment/",
        STUDENT_TEACHERS: "student-teachers/",
        ALL_TEACHERS: "all-teachers",
        ASSIGN_TEACHER: "assign-teacher",
        STUDENT_TEACHER_ASSIGN: "view-assign-teacher",
        DELETE_ASSIGN_TEACHER: "delete-assign-teacher/",
        STUDENT_ATTENDENCE: "student-attendance-notes/",
        STUDENT_MESSAGE_HISTORY: "student-message-history/",
        STUDENT_EMAIL_PARENT: "student-parents/",
        DELETE_STUDENT: "delete-student/",
        DELETE_SELECTED_STUDENT: "delete-selected-student",
        UPDATE_SELECTED_STUDENT: "update-selected-student-status",
        EDIT_FAMILY: "edit-parent",
        ADD_NOTES: "add-note",
        VIEW_STUDENT_NOTES: "view-note/",
        STUDENT_PARENT_ENABLE_DISABLE: "enable-disable-parent/",
        PARENT_RESET_PASSWORD: "reset-parent-password",
        VIEW_PARENT_TOKEN: "view-as-parent-token/",

        //School

        VIEW_SCHOOL_TEACHERS: "school-teachers/",

        //Teacher Compensation

        GET_COMPENSATION: "compensations/",
        ADD_PAYMENT: "add-payment",
        ADD_INCOME_DEDUCTION: "add-income-deduction",
        EDIT_INCOME_DEDUCTION: "edit-income-deduction/",

        VIEW_PAYMENT: "view-payment/",
        EDIT_PAYMENT: "edit-payment/",
        DELETE_PAYMENT: "delete-payment/",
        PARENT_CARD: "parent-card/",

        //Notification

        GET_NOTIFICATIONS: "notifications",
        TOGGLE_NOTIFICATIONS: "toggle-notifications",
        DELETE_NOTIFICATIONS: "delete-notifications",

        //Admin Tab

        GET_NEWS: "news",
        ADD_NEWS: "add-news",

        //Files

        UPLOAD_FILES: "file-upload",

        //Email TEmplate
        ADD_EMAIL_TEMPLATE: "add-email-template",
        GET_EMAIL_TEMPLATE: "email-templates/",
        DELETE_TEMPLATE: "delete-email-template/",
        VIEW_TEMPLATE: "view-email-template/",
        EDIT_EMAIL_TEMPLATE: "edit-email-template",

        CHANGE_PASSWORD: "change-password",
        EDIT_PROFILE: "edit-profile",
        LOGOUT_ALL_DEVICES: "logout-all",
    };
}
